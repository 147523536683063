import axios from "axios";
import { API_SERVER, defaultAxiosConfig } from "../../constants";
import { toast } from "react-toastify";

export const fetchQuote = async (quoteUuid) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${API_SERVER}portal/quotes/guid/${quoteUuid}`,
      headers: defaultAxiosConfig().headers,
    });
    return response.data || {};

  } catch (error) {
    toast.error(error?.response?.data?.message || `Unable to retrieve quote`);
    return {};
  };
};

export const addCustomer = async(quoteUuid, customer) => {
  try {
   await axios({
      method: "POST",
      url: `${API_SERVER}portal/quotes/guid/${quoteUuid}/add-customer`,
      headers: defaultAxiosConfig().headers,
      data: {
        customer,
      },
    });
    return true;
  } catch (err) {
    toast.error(`${err.response?.data?.message || "Unable to add customer"}`);
    return false;
  };
};

export const lookupCustomerByName = async(quoteUuid, businessName) => {
  try {
    const response = await axios({
       method: "POST",
       url: `${API_SERVER}portal/quotes/guid/${quoteUuid}/lookup-customer-by-name`,
       headers: defaultAxiosConfig().headers,
       data: {
         businessName,
       },
     });
     return response.data; // true is already exists
   } catch (err) {
     toast.error(`${err.response?.data?.message || "Unable to lookup name"}`);
     return true;
   };
};
