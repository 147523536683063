import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Spinner from "../components/common/Spinner";
import { addCustomer, fetchQuote, lookupCustomerByName } from "../context/customer/customerActions";
import {
  CustButton,
  CustCheckBox,
  CustCol,
  CustDropDown,
  CustModal,
  CustRow,
  CustText,
  ModalBody,
  ModalFooter,
  SectionTitle,
} from "../components/common";
import logo from "../assets/img/logo.png";
import { CONTACT_TYPE, CUSTOMER_FIELD_NAME, QUOTE_STATUS } from "../constants";

function CustomerAdd() {
  const [searchParams] = useSearchParams();
  const idParm = searchParams.get('id');

  const [localState, setLocalState] = useState({
    isLoading: true,
    quote: {},
    customer: {
      addr1: "",
      addr2: "",
      apEmail: "",
      apFirstName: "",
      apLastName: "",
      apPhone: "",
      businessName: "",
      carrierAcct: "",
      carrierId: "",
      city: "",
      hasOwnShipping: 0,
      isAnotherAP: 0,
      isAnotherShipping: 0,
      purchaserEmail: "",
      purchaserFirstName: "",
      purchaserLastName: "",
      purchaserPhone: "",
      receivingEmail: "",
      receivingFirstName: "",
      receivingLastName: "",
      receivingPhone: "",
      resaleCert: "",
      state: "",
      zip: "",
      country: "",
      isCanada: 0,
    },
    config: {},
    notFound: false,
    touched: true,
    isAdded: false,
    carrierOptions: [
      {key: "", value: "", text: "Select Carrier"},
      {key: "FedEx", value: "FedEx", text: "FedEx"},
      {key: "UPS", value: "UPS", text: "UPS"}
    ],
    businessNameAlreadyExists: false,
    showDuplicateNameModal: false,
  });

  const isFetched = useRef(false);

  const lookupQuote = useCallback(async (id) => {
    if (isFetched.current) return;

    const results = await fetchQuote(id); 
    setLocalState((prev) => {
      const {customer} = prev;
      const {quotingEmail, quotingName, quotingPhone, addr1, addr2, city, state, zip} = results.quote;
      const names = quotingName.split(' ');
      customer.purchaserEmail = quotingEmail;
      customer.purchaserFirstName = names[0];
      customer.purchaserLastName = names.length > 1 ? names.pop() : "";
      customer.purchaserPhone = quotingPhone;
      customer.addr1 = addr1;
      customer.addr2 = addr2;
      customer.city = city;
      customer.state = state;
      customer.zip = zip;

      return {
        ...prev,
        quote: !!results?.quote ? {...results.quote} : {},
        config: !!results?.config ? {...results.config} : undefined,
        customer,
        notFound: !results?.quote?.id,
        isLoading: false,
      }
    });
  }, [setLocalState]);
  

  useEffect(() => {
    if (!idParm || !!isFetched.current) return;

    lookupQuote(idParm);
    isFetched.current = true;
  }, [idParm, lookupQuote]);

  if (localState.notFound) {
    return (
      <h1>Quote not found</h1>
    )
  };

  const handleFormFieldChange = (fieldName, val, maxLength) => {
    let newVal = val;
    if ((maxLength && newVal.length > maxLength) || !!localState.isLoading) return;

    setLocalState((prevState) => {
      const touched =  prevState.touched || (!!newVal && prevState.customer[fieldName] !== newVal);
      const country = fieldName === "isCanada" 
        ? !!val ? "CANADA" : "" 
        : prevState.customer.country

      return {
        ...prevState,
        touched,
        customer: {
          ...prevState.customer,
          [fieldName]: newVal,
          country,
        },
      };
    });
  };

  const verifyBusinessNameDoesNotExist = async() => {
    const {businessName} = localState.customer;
    if (!businessName) return;

    try {
      const isFound = await lookupCustomerByName(idParm, businessName);
      if (!isFound) {
        setLocalState((prev) => { return {...prev, businessNameAlreadyExists: false}});
        return;
      };

      setLocalState((prev) => {
        return {
          ...prev, 
          businessNameAlreadyExists: true,
          showDuplicateNameModal: true,
        };
      });
    } catch (err){
      
    };
  };

  const handleSave = async() => {
    if (!isValid) return;

    setLocalState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });

    const customer = {...localState.customer};

    const customerContacts = [];
    const baseContact = {
      contactTypeId: CONTACT_TYPE.buyer,
      firstName: customer.purchaserFirstName,
      lastName: customer.purchaserLastName,
      addr1: customer.addr1,
      addr2: customer.addr2,
      city: customer.city,
      state: customer.state,
      zip: customer.zip,
      email: customer.purchaserEmail,
      phone: customer.purchaserPhone,
    };

    if (!!customer.isAnotherAP || !!customer.isAnotherShipping) {
      const apContact = {...baseContact, contactTypeId: CONTACT_TYPE.aP};
      if (customer.isAnotherAP) {
      apContact.firstName = customer.apFirstName;
      apContact.lastName = customer.apLastName;
      apContact.email = customer.apEmail;
      apContact.phone = customer.apPhone;
      };

      const receivingContact = {...baseContact, contactTypeId: CONTACT_TYPE.shipping};
      if (customer.isAnotherShipping) {
        receivingContact.firstName = customer.receivingFirstName;
        receivingContact.lastName = customer.receivingLastName;
        receivingContact.email = customer.receivingEmail;
        receivingContact.phone = customer.receivingPhone;
      };

      customerContacts.push(baseContact);
      customerContacts.push(apContact);
      customerContacts.push(receivingContact);
    } else {
      customerContacts.push({...baseContact, contactTypeId: CONTACT_TYPE.allTheHats});
    };

    customer.customerContacts = customerContacts.map((x) => x);

    if (!customer.hasOwnShipping) {
      customer.carrierAcct = null;
      customer.carrierId = null;
    };

    customer.phone = baseContact.phone;
    const result = await addCustomer(idParm, customer);

    setLocalState((prev) => {
      return {
        ...prev,
        isLoading: false,
        isAdded: !!result,
        isError: !result,
      };
    });
 };

  if (localState.isLoading) return <Spinner />;

  const {customer, quote, businessNameAlreadyExists} = localState;
  const {currentStatusId} = quote;
  const alreadyConverted = currentStatusId >= QUOTE_STATUS.convertedToOrder || quote.customerId;
  const isValid = !businessNameAlreadyExists &&
    !!customer.businessName && customer.businessName.length > 4 && !!customer.purchaserEmail && !!customer.purchaserFirstName && !!customer.purchaserLastName && !!customer.purchaserPhone &&
    !!customer.addr1 && !!customer.city && !!customer.state && !!customer.zip &&
    (!customer.isAnotherAP || (!!customer.apFirstName && !!customer.apLastName && !!customer.apEmail && !!customer.apPhone)) &&
    (!customer.isAnotherShipping || (!!customer.receivingEmail && !!customer.receivingFirstName && !!customer.receivingLastName && !!customer.receivingPhone)) &&
    (!customer.hasOwnShipping || (!!customer.carrierAcct && !!customer.carrierId));

  const header = (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
      <h1>{localState.config.clientName}</h1>
      <img src={logo} className="pr-2" alt="logo" style={{ height: "10rem", marginTop: "-15px" }} />
      <label style={{marginTop: "-5px"}}>CALIFORNIA  -  MONTANA  -  OHIO  -  VIENNA</label>
    </div>
  );

  const footer = (
    <div className="mt-20 ml-20 flex-center">
      <p style={{fontSize: "90%"}}>
        {localState.ipAddress} This software was custom designed for {localState.config.clientName}. If you'd like to discuss your custom software needs, contact <a href="https://406partnership.com" target="_blank" rel="noreferrer">406 Partnership, LLC</a> 
      </p>
    </div>
  );

  if (alreadyConverted) return (
    <div>
      {header}
      <div className="ml-20">
        <label className="comp-input-label mt-10 flex-center" style={{fontSize: "120%", lineHeight: 1.3}}>
          We're showing that we already have your customer information on file - nothing for you to do here.<br />
         Questions?  Please contact your rep {quote.staff.name} at {quote.staff.cellPhone} or {quote.staff.email}.
        </label>
      </div>
      {footer}
    </div>
    
  );

  if (!!localState.isAdded) return (
    <div>
      {header}
      <h2 className="text-center mt-10">Thank you!  Everything looks good - we'll be in touch shortly.</h2>
    </div>
  )

  if (!!localState.isError) return (
    <div>
      {header}
      <div className="mt-10" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <h2>Our sincerest apologies - something went wrong.</h2>
        <p className="comp-input-label mt-5" style={{fontSize: "130%"}}>Can you please contact your rep {quote.staff.name} at {quote.staff.cellPhone} or {quote.staff.email}?</p>
        <p className="comp-input-label" style={{fontSize: "130%"}}>Again, we're very sorry for the inconvenience, and we'll have our developers look at this right away. </p>
      </div>
    </div>
  )

  if (!localState.isAdded && !localState.isError) return (
    <>
      {header}
      <div className="ml-10 mt-10">
        <label className="comp-input-label" style={{fontSize: "120%"}}>Hello {customer.purchaserFirstName}. &nbsp;We're glad you're ready to move forward and thrilled that you've given us this opportunity to fulfill your printing needs!<br />
          Let's get you into our system so that we can begin your order.
        </label>
      </div>

      <div>
        <SectionTitle>Your Details</SectionTitle>
        <CustRow>
          <CustCol cols={2}>
            <CustText
              id="purchaserFirstName"
              value={customer.purchaserFirstName}
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
              label="Your First Name (req)"
              setFocus={!localState.touched}
              valid={!!customer.purchaserFirstName}
              maxLength={50}
              showValidationState={localState.touched}
              tabIndex={1}
            />
          </CustCol>

          <CustCol cols={2}>
            <CustText
              id="purchaserLastName"
              value={customer.purchaserLastName}
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
              label="Your Last Name (req)"
              valid={!!customer.purchaserLastName}
              maxLength={50}
              showValidationState={localState.touched}
              tabIndex={2}
            />
          </CustCol>

          <CustCol cols={3}>
            <CustText
              id={CUSTOMER_FIELD_NAME.businessName}
              value={customer.businessName}
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
              label="Business Name (req, min length 5)"
              valid={!!customer.businessName && customer.businessName.length > 4}
              maxLength={50}
              showValidationState={localState.touched}
              tabIndex={3}
              onBlur={verifyBusinessNameDoesNotExist}
            />
          </CustCol>

          <CustCol cols={3}>
            <CustText
              id="purchaserEmail"
              value={customer.purchaserEmail}
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
              label="Your Email"
              required
              valid={!!customer.purchaserEmail}
              maxLength={50}
              showValidationState={localState.touched}
              tabIndex={4}
            />
          </CustCol>

          <CustCol cols={2}>
            <CustText
              id="purchaserPhone"
              value={customer.purchaserPhone}
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
              label="Your Phone"
              required
              valid={!!customer.purchaserPhone}
              maxLength={50}
              showValidationState={localState.touched}
              tabIndex={5}
            />
          </CustCol>
        </CustRow>

        <CustRow>
          <CustCol cols={4}>
            <CustText
              id={CUSTOMER_FIELD_NAME.resaleCert}
              value={customer.resaleCert}
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
              label="For Resellers - Resale Certificate or TIN"
              maxLength={30}
              tabIndex={12}
            />
          </CustCol>
        </CustRow>

        <CustRow>
          <CustCol cols={4}>
            <CustCheckBox
              id="isAnotherShipping"
              label="Does someone else receive shipments?"
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.checked)}
              value={customer.isAnotherShipping}
              tabIndex={13}
            />
          </CustCol>

          <CustCol cols={3}>
            <CustCheckBox
              id="isAnotherAP"
              label="Does someone else handle billing?"
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.checked)}
              value={customer.isAnotherAP}
              tabIndex={14}
            />
          </CustCol>

          <CustCol cols={3}>
            <CustCheckBox
              id="hasOwnShipping"
              label="Do you have your own FedEx or UPS Account?"
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.checked)}
              value={customer.hasOwnShipping}
              tabIndex={14}
            />
          </CustCol>
        </CustRow>

        <CustRow>
          <CustCol cols={4}>
            <CustText
              id={CUSTOMER_FIELD_NAME.addr1}
              value={customer.addr1}
              required
              valid={!!customer.addr1}
              showValidationState={localState.touched}
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
              label={!!customer.businessName ? `${customer.businessName}'s physical address` : "Physical Address"}
              maxLength={50}
              tabIndex={21}
            />
          </CustCol>

          <CustCol cols={3}>
            <CustText
              id={CUSTOMER_FIELD_NAME.addr2}
              value={customer.addr2}
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
              label="Line 2"
              maxLength={50}
              tabIndex={22}
            />
          </CustCol>

          <CustCol cols={4}>
            <CustCheckBox
              id="isCanada"
              label="Are you in Canada?"
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.checked)}
              value={customer.isCanada}
              tabIndex={23}
            />
          </CustCol>
        </CustRow>

        <CustRow>
          <CustCol cols={4}>
            <CustText
              id={CUSTOMER_FIELD_NAME.city}
              required
              valid={!!customer.city}
              showValidationState={localState.touched}
              value={customer.city}
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
              label={customer.isCanada ? "Municipality" : "City"}
              maxLength={50}
              tabIndex={23}
            />
          </CustCol>

          <CustCol cols={2}>
            <CustText
              id={CUSTOMER_FIELD_NAME.state}
              value={customer.state}
              valid={!!customer.state}
              showValidationState={localState.touched}
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
              label={customer.isCanada ? "Province" : "State"}
              tabIndex={24}
            />
          </CustCol>

          <CustCol cols={3} className="pr-0">
            <CustText
              id={CUSTOMER_FIELD_NAME.zip}
              value={customer.zip}
              valid={!!customer.zip}
              showValidationState={localState.touched}
              onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
              label={customer.isCanada ? "Postal Code" : "Zip"}
              tabIndex={25}
            />
          </CustCol>
        </CustRow>

        {!!customer.isAnotherShipping && 
          <div className="mt-10">
            <SectionTitle>Receiver Details</SectionTitle>
            <CustRow>
              <CustCol cols={2}>
                <CustText
                  id="receivingFirstName"
                  value={customer.receivingFirstName}
                  onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
                  label="Receiving's First Name (req)"
                  valid={!!customer.receivingFirstName}
                  maxLength={50}
                  showValidationState={localState.touched}
                  tabIndex={31}
                />
              </CustCol>

              <CustCol cols={2}>
                <CustText
                  id="receivingLastName"
                  value={customer.receivingLastName}
                  onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
                  label="Receiving's Last Name (req)"
                  valid={!!customer.receivingLastName}
                  maxLength={50}
                  showValidationState={localState.touched}
                  tabIndex={32}
                />
              </CustCol>

              <CustCol cols={4}>
                <CustText
                  id="receivingEmail"
                  value={customer.receivingEmail}
                  onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
                  label="Receiver's Email"
                  required
                  valid={!!customer.receivingEmail}
                  maxLength={50}
                  showValidationState={localState.touched}
                  tabIndex={32}
                />
              </CustCol>

              <CustCol cols={4}>
                <CustText
                  id="receivingPhone"
                  value={customer.receivingPhone}
                  onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
                  label="Receiver's Phone"
                  required
                  valid={!!customer.receivingPhone}
                  maxLength={50}
                  showValidationState={localState.touched}
                  tabIndex={33}
                />
              </CustCol>
            </CustRow>
          </div>
        }

        {!!customer.isAnotherAP && 
          <div className="mt-10">
            <SectionTitle>Accounts Payable Details</SectionTitle>
            <CustRow>
              <CustCol cols={2}>
                <CustText
                  id="apFirstName"
                  value={customer.apFirstName}
                  onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
                  label="A/P Person's First Name"
                  required
                  valid={!!customer.apFirstName}
                  maxLength={50}
                  showValidationState={localState.touched}
                  tabIndex={41}
                />
              </CustCol>

              <CustCol cols={2}>
                <CustText
                  id="apLastName"
                  value={customer.apLastName}
                  onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
                  label="Last Name"
                  required
                  valid={!!customer.apLastName}
                  maxLength={50}
                  showValidationState={localState.touched}
                  tabIndex={42}
                />
              </CustCol>

              <CustCol cols={4}>
                <CustText
                  id="apEmail"
                  value={customer.apEmail}
                  onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
                  label="A/P Email"
                  required
                  valid={!!customer.apEmail}
                  maxLength={50}
                  showValidationState={localState.touched}
                  tabIndex={43}
                />
              </CustCol>

              <CustCol cols={4}>
                <CustText
                  id="apPhone"
                  value={customer.apPhone}
                  onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
                  label="A/P Phone"
                  required
                  valid={!!customer.apPhone}
                  maxLength={50}
                  showValidationState={localState.touched}
                  tabIndex={44}
                />
              </CustCol>
            </CustRow>
          </div>
        }

        {!!customer.hasOwnShipping && 
          <div className="mt-10">
            <SectionTitle>Shipping Account Details</SectionTitle>
            <CustRow>
              <CustCol cols={2}>
                <CustDropDown
                  id={CUSTOMER_FIELD_NAME.carrierId}
                  selectedValue={customer.carrierId}
                  label="Carrier"
                  onChange={(e) => {
                    handleFormFieldChange(e.target.id, e.target.value);
                  }}
                  tabIndex={51}
                  options={localState.carrierOptions}
                  valid={!!customer.carrierId}
                  showValidationState={localState.touched}
                />
              </CustCol>

              <CustCol cols={2}>
                <CustText
                  id={CUSTOMER_FIELD_NAME.carrierAcct}
                  value={customer.carrierAcct}
                  onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
                  label="Carrier Acct"
                  tabIndex={52}
                  valid={!!customer.carrierAcct}
                  showValidationState={localState.touched}
                />
              </CustCol>
            </CustRow>
          </div>
        }

        <div className="mt-10 ml-5">
          <CustButton
            text="Save"
            onClick={async() => await handleSave()}
            disabled={!isValid || localState.isError}
            tabIndex={101}
          />

          <CustButton
            text="Cancel"
            stylingMode="outlined"
            tabIndex={102}
          />
        </div>

        <CustModal
          id="duplicate-business-name"
          title="There is only one you, but..."
          showModal={localState.showDuplicateNameModal}
          onClose={() => setLocalState((prev) => {return {...prev, showDuplicateNameModal: false}})}
          size="small"
        >
          <ModalBody>
            <p className="comp-input-label">It looks like {localState.customer.businessName} is already in our system.</p>
          </ModalBody>
          <ModalFooter>
            <CustButton
              id="ack-dup-business-name"
              text="OK"
              color="secondary"
              onClick={() => setLocalState((prev) => {return {...prev, showDuplicateNameModal: false}})}
            />
          </ModalFooter>
        </CustModal>

      </div>

      {footer}
    </>
  );
}

export default CustomerAdd;
