import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../components/common/Spinner";
import { acceptArtwork, contactRep, fetchOrder } from "../context/order/orderActions";
import {
  CustButton,
  CustCol,
  CustRow,
} from "../components/common";
import logo from "../assets/img/logo.png";
import { toast } from "react-toastify";
import { NAVIGATION, ORDER_STATUS } from "../constants";

function Artwork() {
  const [searchParams] = useSearchParams();
  const idParm = searchParams.get('id');
  const isApproval = searchParams.get('approval')
  const isNeedsWork = searchParams.get('needs-work');
  const navigate = useNavigate();

  const [localState, setLocalState] = useState({
    isLoading: true,
    order: {customer: {}},
    config: {},
    notFound: false,
    accepted: null,
    notes: "",
    notesSent: false,
    isFinalCancel: false,
    showPrepay: false,
  });

  const isFetched = useRef(false);

  const lookupOrder = useCallback(async (id) => {
    if (isFetched.current) return;

    const results = await fetchOrder(id, true); 
    setLocalState((prev) => {
      return {
        ...prev,
        order: !!results?.order ? {...results.order} : undefined,
        config: !!results?.config ? {...results.config} : undefined,
        notFound: !results?.order?.id,
        isLoading: false,
      }
    });
  }, [setLocalState]);
  

  useEffect(() => {
    if (!idParm || !!isFetched.current) return;

    lookupOrder(idParm);
    isFetched.current = true;
  }, [idParm, lookupOrder]);

  const setupPrepay = () => {
    // setLocalState((prev) => { return {...prev, showPrepay: true}});
    navigate(`${NAVIGATION.pay}/?id=${idParm}&artwork=true`);
  };

  const handleAuthorization = async (accepted) => {
    if (localState.accepted !== null) return; 

    try {
      if (accepted) {
        await acceptArtwork(idParm);
      };

      setLocalState((prev) => {
        return {
          ...prev,
          accepted,
        }
      });
    } catch (err) {
      toast.error("Unable to authorize at this time");
    };
  };

  const handleContactRep = async (notes) => {
    if (!notes || !!localState.notesSent || !!localState.isFinalCancel) return;

    try {
      await contactRep(
        {
          notes,
          orderId: order.id,
          customerName: order.customer?.businessName,
          emailStatus: ORDER_STATUS.needsWorkEmailSentToRep,
          currentStatusId: ORDER_STATUS.artworkRejected
        }, idParm
      );
      toast.success("Email sent");

      setLocalState((prev) => {
        return {
          ...prev,
          notesSent: true,
        }
      });
    } catch (err) {
      toast.error("Unable to send email");
    };
  };

  if (localState.notFound) {
    return (
      <h1>Order not found</h1>
    )
  };

  if (localState.isLoading) return <Spinner />;
  const {order} = localState;
  const {currentStatusId} = order;
  const alreadyApproved = currentStatusId >= ORDER_STATUS.artworkApproved && currentStatusId !== ORDER_STATUS.artworkRejected;

  const header = (
    <div>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
        <h1>{localState.config.clientName}</h1>
        <img src={logo} className="pr-2" alt="logo" style={{ height: "10rem", marginTop: "-15px" }} />
        <label style={{marginTop: "-5px"}}>CALIFORNIA  -  MONTANA  -  OHIO  -  VIENNA</label>
      </div>
      <div className="mt-5">
        <CustRow className="mt-2">
          <CustCol cols={12}>
            <CustRow>
              <CustCol cols={12}>
                <h2>{order.customer?.businessName}</h2>
              </CustCol>
            </CustRow>
            <CustRow>
              <CustCol cols={12}>
                <label className="comp-input-label">Your Rep is {order.staff?.name}, who may be contacted at {order.staff?.cellPhone}{order.staff?.email ? `, and emailed at ${order.staff?.email}` : ""}</label>
              </CustCol>
            </CustRow>

            <CustRow className="mt-2">
              <CustCol cols={10}>
                {!!order?.customerPo && (<><label className="comp-input-label">PO {order?.customerPo}</label><br /></>)}
                <label className="comp-input-label">Order # {order.id}</label><br />
                <label className="comp-input-label">Terms are {order.terms}</label>
              </CustCol>
            </CustRow>
          </CustCol>
        </CustRow>
      </div>
    </div>
  );

  const footer = (
    <div className="mt-20 ml-20">
      <p style={{fontSize: "80%"}}>
        {localState.ipAddress} This software was custom designed for {localState.config.clientName}. If you'd like to discuss your custom software needs, contact <a href="https://406partnership.com" target="_blank" rel="noreferrer">406 Partnership, LLC</a> 
      </p>
    </div>
  );

  if (alreadyApproved) return (
    <>
      {header}
      <div className="ml-20">
        <label className="comp-input-label mt-10">We're showing that the artwork has already been approved - nothing for you to do here. &nbsp;Please contact your rep if you'd like to discuss.</label>
      </div>
      {footer}
    </>
  );

  if (isNeedsWork) return (
    <>
      {header}
      <div className="mt-10 ml-20" style={{lineHeight: 1.3}}>
        <label className="comp-input-label">
          Thank you for carefully reviewing the printer proof(s). &nbsp;<br />
          Since you have indicated that you have questions or concerns, please outline what those are, and we'll be in touch with you shortly.
        </label><br />

        <textarea
          id="needs-work-notes"
          className="comp-input"
          value={localState.notes}
          type="textarea"
          onChange={(e) => setLocalState((prev) => {return {...prev, notes: e.target.value}})}
          label="Notes"
          maxLength={10000}
          disabled={localState.notesSent || localState.isFinalCancel}
          style={{ minHeight: "75px", width: "50%" }}
        />

        <div className="mt-5">
          <CustButton
            onClick={() => handleContactRep(localState.notes)}
            text="Send Note"
            disabled={!localState.notes || localState.notesSent}
          />

          <CustButton
            onClick={() => setLocalState((prev) => {return {...prev, isFinalCancel: true}})}
            text="Cancel"
            stylingMode="outlined"
            disabled={localState.isFinalCancel || localState.notesSent}
          />
        </div>

        {(localState.isFinalCancel || localState.notesSent) && (
          <label className="comp-input-label mt-10">Thank you. &nbsp;You may now close this window.</label>
        )}
      </div>
      {footer}
    </>
  )

  if (isApproval) return (
    <>
      {header}
      <div className="ml-20 mt-10">
        <label className="mb-5 comp-input-label">I acknowledge that I have reviewed all printer proofs associated with my PO {order?.customerPo}, and authorize submitting the order to production.</label><br />
        <CustButton
          onClick={() => !!localState.order?.prepayAmountNeeded ? setupPrepay() : handleAuthorization(true)}
          text={!!localState.order?.prepayAmountNeeded ? `Authorize and Prepay ${localState.order?.upfrontPercent}%` : "Authorize"}
          disabled={localState.accepted !== null}
        />

        <CustButton
          onClick={() => handleAuthorization(false)}
          text="Cancel"
          stylingMode="outlined"
          disabled={localState.accepted !== null}
        />

        {localState.accepted && (
          <div className="mt-10" style={{lineHeight: 1.2}}>
            <label className="comp-input-label">Now that you've approved the art proof(s) we'll schedule the job for printing and send you an estimate on the shipping date.</label><br />
            <label className="mt-5 comp-input-label">Thank you very much - you may now close this window.</label>
          </div>
        )}
        
        {localState.accepted === false && (
          <div className="mt-10" style={{lineHeight: 1.3}}>
            <label className="comp-input-label">This is to confirm that you have chosen not to authorize the artwork at this time.<br />
            You can come back to this later, or if you have questions or concerns you can drop a note to your rep right here:</label><br />

            <textarea
              id="defer-notes"
              className="comp-input"
              value={localState.notes}
              type="textarea"
              onChange={(e) => setLocalState((prev) => {return {...prev, notes: e.target.value}})}
              label="Notes"
              maxLength={10000}
              disabled={localState.notesSent || localState.isFinalCancel}
              style={{ minHeight: "75px", width: "50%" }}
            />

            <div className="mt-5">
              <CustButton
                onClick={() => handleContactRep(localState.notes)}
                text="Send Note"
                disabled={!localState.notes || localState.notesSent}
              />

              <CustButton
                onClick={() => setLocalState((prev) => {return {...prev, isFinalCancel: true}})}
                text="Cancel"
                stylingMode="outlined"
                disabled={localState.notesSent || localState.isFinalCancel}
              />
            </div>

            {(localState.isFinalCancel || localState.notesSent) && (
              <label className="comp-input-label mt-10">Thank you. &nbsp;You may now close this window.</label>
            )}
          </div>
        )}
      </div>

      {footer}
    </>
  );
}

export default Artwork;
