export const API_SERVER =
  window.location.hostname.indexOf("localhost") >= 0
    ? "http://localhost:8080/"
    : `https://api.${window.location.hostname}/`.replace("customer.", "proof.");

export const NAVIGATION = {
  home: "/",
  about: "/about",
  artwork: "/artwork",
  customerAdd: "/customer-welcome",
  pay: "/pay",
};

export const QUOTE_STATUS = {
  closed: "q100",
  initialEntry: "q200",
  rfqSent: "q300",
  quoteSent: "q400",
  newCustomerEmailSent: "q450",
  convertedToOrder: "q500",
};

// ensure that this is in sync with constant AppDataContext initialSelectState.selectState.orderStatus and server side constants
export const ORDER_STATUS = {
  creditHold: "-95",
  creditHoldRelease: "-90",
  vendorPaymentAchEmailSent: "-85",
  estimatedShipDateEmailSent: "-80",
  needsWorkEmailSentToRep: "-70",
  needsWorkEmailSentToCustomer: "-60",
  artworkConfirmationEmailSent: "-50",
  purchaseOrderEmailSent: "-40",
  invoiceEmailSent: "-30",
  orderScheduledConfirmationEmailToCustomer: "-28",
  authorizationToBeginProductionEmail: "-26",
  contactRepEmail: "-24",
  statementEmail: "-22",
  canceled: "-20",
  onHold: "-10",
  needsApproval: "010",
  awaitingPrepay: "020",
  needsPrinterAssignment: "030",
  awaitingCustomerArtwork: "040",
  needsPurchaseOrder: "050",
  awaitingPrinterProof: "060",
  awaitingCustomerApproval: "070",
  artworkApproved: "080",
  artworkRejected: "090",
  shipped: "200",
  waitingForShippingInvoice: "250",
  readyForInvoicing: "300",
  invoiced: "400",
  paid: "500",
};

// sync terms with server constants
export const TERMS = [
  { id: "Net 10", daysDue: 10, upFrontPercent: 0, discountPercent: 0, discountDays: 0 },
  { id: "Net 30", daysDue: 30, upFrontPercent: 0, discountPercent: 0, discountDays: 0 },
  { id: "Net 60", daysDue: 60, upFrontPercent: 0, discountPercent: 0, discountDays: 0 },
  { id: "1% / 10, Net 30", daysDue: 30, upFrontPercent: 0, discountPercent: 1, discountDays: 10},
  { id: "0.75% / 10, Net 30", daysDue: 30, upFrontPercent: 0, discountPercent: .75, discountDays: 10},
  { id: "Due upon receipt", daysDue: 0, upFrontPercent: 0, discountPercent: 0, discountDays: 0 },
  { id: "50% upfront, 50% on delivery", daysDue: 0, upFrontPercent: 50, discountPercent: 0, discountDays: 0 },
  { id: "100% upfront", daysDue: 0, upFrontPercent: 100, discountPercent: 0, discountDays: 0 },
];

export const CONTACT_TYPE = {
  aP: 1,
  buyer: 2,
  shipping: 3, 
  aR: 4,
  salesRep: 5,
  allTheHats: 99,
};

export const CUSTOMER_FIELD_NAME = {
  id: "id",
  businessName: "businessName",
  resaleCert: "resaleCert",
  blanketPo: "blanketPo",
  blanketPoExpiration: "blanketPoExpiration",
  addr1: "addr1",
  addr2: "addr2",
  city: "city",
  state: "state",
  zip: "zip",
  country: "country",
  phone: "phone",
  carrierId: "carrierId",
  carrierAcct: "carrierAcct",
};

export const LOGGING = {
  level: {
    info: "info",
    warn: "warn",
    error: "error",
    critical: "critical",
  },
  category: {
    login: "login",
    logout: "logout",
    errorBoundary: "error boundary",
  },
};

export const defaultAxiosConfig = () => {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
  };
};

export const WINDOW_OPEN_PARMS = `width=0,height=0,left=-1000,top=1`;